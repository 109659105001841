/**
* Generated automatically at built-time (2024-07-26T22:35:01.961Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-weinkuehlschrank",templateKey: "sites/86-cf9b34cd-39fb-463b-8edb-947198e75700"};